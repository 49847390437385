import React, { Component } from 'react';
import './css/bootstrap.css';
import 'antd/dist/antd.css'
import './css/main.css';
import './css/new.css';
import './css/carousel.css';
import './css/LineIcons.css';
import './css/default.css';
import './css/font-awesome/css/font-awesome.min.css';

import { BrowserRouter, Route, Redirect, Routes, useNavigate } from 'react-router-dom';

import Home from './Home';
import About from './About';
import Research from './Research';
import axios from 'axios';
import Contact from './Contact';
import Register from './Register';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import Workshops from './Workshops';
import Blog from './Blog';
import FutureSelf from './blog/FutureSelf';
import Sorry from './blog/Sorry';
import CloserFurther from './blog/CloserFurther';
import DirectYourMoment from './blog/DirectYourMoment';
import LittleThingsRight from './blog/LittleThingsRight';
import LivingProactively from './blog/LivingProactively';
import Resentment from './blog/Resentment';
import SelfCompassion from './blog/SelfCompassion';
import BookPurchase from './BookPurchase';
import Admin from './Admin';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  //<Route exact path='/preview' render={(props) => <Home {...props} preview={true} />} />
  render() {

return (
      <div className="App">
        <BrowserRouter>
          <div>
            <Route exact path='/' component={Home} />
            <Route exact path='/About' component={About} />
            <Route exact path='/Contact' component={Contact} />
            <Route exact path='/PrivacyPolicy' component={PrivacyPolicy} />
            <Route exact path='/Register' component={Register} />
            <Route exact path='/Research' component={Research} />
            <Route exact path='/TermsOfUse' component={TermsOfUse} />
            <Route exact path='/Blog' component={Blog} />
            <Route exact path='/Workshops' component={Workshops} />
            <Route exact path='/FutureSelf' component={FutureSelf} />
            <Route exact path='/Sorry' component={Sorry} />
            <Route exact path='/CloserFurther' component={CloserFurther} />
            <Route exact path='/DirectYourMoment' component={DirectYourMoment} />
            <Route exact path='/LittleThingsRight' component={LittleThingsRight} />
            <Route exact path='/LivingProactively' component={LivingProactively} />
            <Route exact path='/Resentment' component={Resentment} />
            <Route exact path='/SelfCompassion' component={SelfCompassion} />
             <Route exact path='/BookPurchase' component={BookPurchase} />
             <Route exact path='/Admin' component={Admin} />
                      </div>
        </BrowserRouter>
      </div>
            );
    /*return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path='/' element={<Home />}></Route>
            <Route exact path='/About' element={<About />}></Route>
            <Route exact path='/Research' element={<Research />}></Route>
            <Route exact path='/Contact' element={<Contact />}></Route>
            <Route exact path='/Register' element={<Register />}></Route>
            <Route exact path='/PrivacyPolicy' element={<PrivacyPolicy />}></Route>
            <Route exact path='/TermsOfUse' element={<TermsOfUse />}></Route>


          </Routes>
        </BrowserRouter>
      </div>
    );*/

  }

}

export default App;
