import React, { Component } from 'react';
import '../css/articles.css';
import Header from '../components/header';
import Footer from '../components/footer';

class SelfCompassion extends Component {

componentDidMount() {
    window.scroll(0,0);
}

	render() {
		return (
		<div className="Sorry">
		<Header history={this.props.history} />

 
<section className="two-sided-box">

       <div className="container">
              <h1>The Power of Self-Compassion</h1><hr />
            <div className="two-sided-box-in">
    <p>
let’s talk about compassion. Compassion and empathy are sometimes confused. But empathy is identifying someone else’s feelings or emotional states. Compassion is taking action on empathetic understanding. It’s taking active participation in improving the wellbeing of another person. It is easy to think of being compassionate towards other people, especially those we care about. If our best friend comes to us upset, we do everything in our power to help them through it.
 We don’t condemn them or ridicule them. We excuse indiscretions. We accept their limitations. We identify with their problems. But why can’t or don’t we do this for ourselves?   
 </p> <p>
Such an overused word so often declared without the action behind it to make the difference to the offended – sorry. As if to say, “I’m letting you know that I know I did something wrong and I feel badly about it so I think uttering that word will make you feel better and absolve me of my wrong doing”. Sure, apologies are nice as a starting point to express regret, but there is a difference between “Hey, I’m sorry”, and truly making amends. That difference is, action.     </p>
    <p>
It might feel artificial or too “RA RA” for us, but we can be downright cruel to ourselves. We push ourselves around. We bully ourselves. We are our harshest critics, but what we do for others, we do for a reason. We understand that showing positive reinforcement to someone else will encourage them to do better. We understand that being harsh, mean, and bullying someone will not motivate them to make true change. They might momentarily change out of fear or a sense of feeling bad about what they've done, but we understand that if we want to see true change, we have to show compassion and identify with that person's challenge. We encourage them.    </p>
    <p>
But that understanding seems to be lost for how to motivate ourselves and how to move along our journey towards living an Optimal Life. I have seen this over and over in the workshops I do for organizations as part of Mental Fitness Training. Someone will feel stuck and talk about procrastinating. They talk about failing. They talk about getting frustrated, angry, and upset and that ends up being directed at themselves. They start to ridicule themselves, thinking that will somehow move them in the direction that they need to go. But I guide them to realizing the same concept that applies to other people applies to them. Of course, we intuitively know this because we are on the receiving end of others both showing compassion towards us and not showing compassion towards us. We know what works and what doesn't. So, I would encourage you to allow yourself to be compassionate towards yourself. Express self-compassion. Understand that momentary failures are just part of the process towards success and living an Optimal Life. 
 </p><p>
 When it comes to personally evolving to living a better life, we aren’t talking about a linear journey, one successful step after another. It might be two steps forward, one step sideways. It is natural to feel momentary frustrations when things aren’t going as you want, but that’s why self-compassion is so crucial because it is only through that understanding that you can side step your way back on the path to your Optimal Life!
 </p>
    </div></div>
    </section>

<Footer history={this.props.history} />

</div>
		);
		
	}
}

export default SelfCompassion;