import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import './css/profile.css';
import { Card } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import FontAwesome from 'react-fontawesome';
import faStyles from './css/font-awesome/css/font-awesome.css';
import { Link } from 'react-router-dom';
import elliot from './images/elliot.jpg';
import scale from './images/At_Scale2.jpg';
import cover from './images/book_cover3.jpg';
import './css/articles.css';


class Research extends Component {

	constructor(props) {
		super(props);

	}

	componentDidMount() {
		window.scroll(0, 0);
	}

	render() {
			return (<div className="Research">
				<Header history={this.props.history} />

               <section id="testimonial" className="testimonial-area">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-5 col-lg-6">
                                <div className="testimonial-left-content mt-45">
                                    <h4 className="title mb-2"><u>The Research</u> Past and Present </h4>

                                    <p className="text">
                                        This page is meant to introduce you to the previous research studies Elliot has conducted and highlight current research studies that
                                        are underway. This is not meant to be a comprehensive description. To inquire about previous or current research studies, or 
                                        to possibly participate in research studies Elliot is conducting, please use the <Link to="/Contact">contact</Link> form. Enjoy the included
                                        video that describes the research studies that empowered <a href="https://mycoreinsights.com/mft">Mental Fitness Training</a>.                      
                                        </p>
    
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                  <div className="author-info d-flex align-items-center justify-content-center pt-5">
                    <iframe title="video" src="https://player.vimeo.com/video/563833189" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="testimonial" className="testimonial-area">
                
                <div className="container">
                                            <div className="col-lg-12">
                                <div className="single-features mt-40">
<div className="text-center"><h2>Language Association and Categorization and the Introspection Illusion</h2></div>
<p><span style={{fontWeight: "bold"}}>Sample Size:</span> 75 </p>
                <p><span style={{fontWeight: "bold"}}>Initial study dates:</span> 2011-2013</p>
                <p><span style={{fontWeight: "bold"}}>Main claim(s):</span> This study explored the intricate relationship between language use and psychological self-awareness. Central to this study is the concept of the Introspection Illusion, a cognitive bias where individuals overestimate their understanding of their mental states and psychological structure <Link className="link" to="https://onlinelibrary.wiley.com/doi/abs/10.1002/mar.20099">1</Link>. The study posits that by analyzing and categorizing the language individuals use to describe themselves and their life experiences, it is possible to overcome or mitigate the effects of this illusion.

This approach is grounded in the idea that language is not merely a tool for communication but also a window into the subconscious and the person's underlying psychological structure. By categorizing language into frameworks used in Cognitive Behavioral Therapy (CBT) such as generalizations, mind reading, maximizations, etc., as well as virtues and cognitive biases, a clearer picture of an individual's psychological makeup can be obtained. This process creates a therapeutic "asset" referred to as the Language Association and Categorization System, that can be used to provide both the individual and the therapist deeper insights into the person's beliefs, values, principles, and challenges.

The study's claim is that this method not only helps in overcoming the introspection illusion but also enhances therapeutic effectiveness. By providing a more accurate understanding of an individual's strengths and areas of challenge, it contributes to improved mental well-being and life satisfaction. This clarity is essential for effective therapeutic transference, fostering a more productive therapist-client relationship.

The results of this study offer a novel approach to understanding and improving mental health by leveraging the power of language as a diagnostic and therapeutic tool. This study aligns with existing research on implicit social cognition, which shows that implicit measures can uniquely predict social behaviors and attitudes, further supporting the idea that subconscious language patterns can reveal deeper psychological structures <Link className="link" to="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3073696">2</Link>.

                 </p>
                    <p><span style={{fontWeight: "bold"}}>Methodology:</span> Research participants were interviewed and assessed for their current self-reported scores of subjective well being. They were then asked to describe major parts of who they are including positive attributes, attributes they aspired to 
                    live more consistently, what their underlying beliefs and values are, and negative aspects of who they are. Each sentence provided was then put into a single list, seperating it from 
                    its origination. Each sentence was then categorized into one of 15 negative categories (Cognitive Traps), 6 positive categories (Virtues), and 10 cognitive biases. Participants then counted each category to produce a "score". This produced a hierarchy of prevalence for each of the categories and provided a basis for discussions around areas of challenge (Cognitive Traps and Biases) and strengths (Virtues). Additional clarity was gained in examining the source of the language. If negative categorizations were assigned to areas describing positive aspects of the person's psychological structure, this providing a possible starting point for further examination.
                    Participants were then instructed to practice using the language associated with the positive categories and actively change language associated with the negative categories and to report on their subjective well being.
                    </p>
               <p><span style={{fontWeight: "bold"}}>Main findings:</span> After completing the analysis of the categorizations produced by the LACS, 95% of the participants reported new discoveries under the 
               original categories they were asked to provide information about with 78% reporting significant discoveries. After 3 months of practicing using the language associated with the positive aspects 
               of who they are versus the negative aspects of who they are, the majority of participants reported their subjective wellbeing positively increased and that less of the negative categories showed 
               up after reassessing the original results.</p>
                </div></div></div>
                </section>  
                               <section id="testimonial" className="testimonial-area">
                
                <div className="container">
                                            <div className="col-lg-12">
                                <div className="single-features mt-40">
                <div className="text-center"><h2>Psychological Dissonance and its Role in Physiological Stress and Subjective Well-Being</h2></div>
                <p><span style={{fontWeight: "bold"}}>Sample Size:</span> 63</p>
                <p><span style={{fontWeight: "bold"}}>Initial study dates:</span> 2013-2014</p>
                <p><span style={{fontWeight: "bold"}}>Main claim(s):</span> This study explored the relationship between psychological dissonance or what was referred to as Core Disharmonies as a fundamental source of stress and a determinant of subjective well-being. This study posits that a discrepancy between an individual's internal identity and the identity they present to the world leads to psychological dissonance, which in turn triggers stress responses, potentially harming both mental and physical health.

This dissonance, or Core Disharmony, is theorized to stem from the conflict between how individuals perceive themselves internally and how they wish to be perceived by others. Such discrepancies can lead to internal stress, diminishing subjective well-being and increasing the risk of various health issues. The study leverages the previously described Language Association and Categorization System to identify potential sources of this dissonance. By analyzing the language used by individuals to describe positive and negative aspects of their psychological structure, the study aims to uncover underlying conflicts. For instance, if language from positive self-descriptions aligns with language typically associated with negative emotions or states, it may indicate the presence of psychological dissonance.

Furthermore, the study explores how free associations to self-reported language can reveal additional layers of dissonance. If individuals associate negative connotations with aspects of themselves they perceive as positive, it further underscores the presence of internal conflict.

This approach is supported by existing research on the interaction between physical and psychosocial stressors and their combined impact on health <Link className="link" to="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7247805">1</Link> as well as studies on the influence of emotional dissonance on subjective health and job satisfaction <Link className="link" to="https://onlinelibrary.wiley.com/doi/10.1111/j.1559-1816.2010.00697.x">2</Link>. These studies highlight the significant role of internal psychological states in overall health and well-being.

In summary, "Psychological Dissonance and its Role in Physiological Stress and Subjective Well-Being" seeks to advance our understanding of the intricate relationship between self-perception, psychological dissonance, and health, offering new insights into therapeutic approaches for enhancing mental and physical well-being.
</p>
                    <p><span style={{fontWeight: "bold"}}>Methodology:</span> Building upon the previous research findings and the LACS, research participants were shown where the associations between positive aspects of who they are and negative aspects of who they are (identified through self-reporting their Mental Core and examining results of the previous associations). Participants were then asked to reexamine the originating situations that language represented and were assessed through 
                    questionaires to ascertain the impact those moments had on them. The assessment targeted their subjective wellbeing and stress responses. These results were compared and contrasted with assessments previously done for their overall subjective wellbeing with the intent of drawing correlations between those stressful events where Core Disharmonies were identified and their baseline subjective wellbeing. Associations of language from positive aspects of who they are that appeared in negative aspects of who they are were examined for potential opportunities to leverage positive aspects of their psychological structure in times of stress. Associations from the negative aspects of their psychological structure appearing in the language associated with positive aspects of their psychological structure were highlighted as potential sources of stress and dissonance.
                    Participants were instructed to practice expressing positive language constructs in related situations that arose over a 3 month period. This included internal dialogue as well as expressed dialogue. </p>
               <p><span style={{fontWeight: "bold"}}>Main findings:</span> Each participant was brought through a process of examining their personal Language Association System. 95% of the participants reported new discoveries under the 
               original categories they were asked to provide information about with 78% reporting significant discoveries. After 3 months of practicing using the language associated with the positive aspects 
               of who they are versus the negative aspects of who they are, the majority of participants reported their subjective wellbeing positively increased and that less of the negative categories showed 
               up after reassessing the original results.</p>
                </div></div></div>
                </section>  
                                               <section id="testimonial" className="testimonial-area">
                
                <div className="container">
                                            <div className="col-lg-12">
                                <div className="single-features mt-40">
                <div className="text-center"><h2>Influencing Predictive Response Cycles to Improve Mental Health</h2></div>
<p><span style={{fontWeight: "bold"}}>Sample Size:</span> 51</p>
                <p><span style={{fontWeight: "bold"}}>Initial study dates:</span> 2014-2015</p>
                <p><span style={{fontWeight: "bold"}}>Main claim(s):</span> 
This study explores the application of predictive coding models of the brain in mental health treatments. It builds upon the clarity provided by the Language Association and Categorization System (LACS) from previous studies, aiming to intervene mindfully in the brain's predictive processes. The central concept of Predictive Response Cycles (PRC) suggests that individuals often operate in habitual cycles of subconscious prediction, response, and resultant feedback, which can negatively impact their mental health when these cycles are maladaptive.

The study posits that while direct control over subconscious predictions is not possible, it is feasible to influence and train the subconscious to generate more beneficial predictions. This process involves creating action plans based on the positive aspects of an individual's psychological structure, as identified through the LACS. These action plans are designed to be implemented in response to triggers, allowing for a gap between subconscious prediction and conscious response. By choosing to act according to the plan rather than habitual responses, individuals can achieve more positive outcomes, which in turn feedback into the subconscious, promoting healthier predictive cycles.

This approach is supported by research on the relationship between resilience and mental health, indicating that proactive strategies can lead to improved mental health outcomes <Link className="link" to="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7012791">1</Link>. Additionally, the use of wearable sensors for mental health monitoring aligns with this study's focus on recognizing and responding to triggers, as these technologies can aid in identifying physiological markers of stress or mental health crises <Link className="link" to="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9919280">2</Link>.

In summary, "Influencing Predictive Response Cycles to Improve Mental Health" offers a novel approach to mental health treatment, focusing on altering subconscious predictive patterns through conscious intervention and action planning. This method holds potential for transforming negative mental health cycles into positive ones, contributing to overall well-being.

                </p>
                    <p><span style={{fontWeight: "bold"}}>Methodology:</span> RBuilding upon the previous research findings and the Language Association System, research participants were asked to identify situations that normally evoke negative responses (Cognitive Catalysts). Using the LAS, each participant was asked what negative aspects of their Mental Core was being expressed and if they were to be able to respond again by their design, what aspects of their Mental Core would they have used and how would they have responded. Each participant was then asked to create Action Plans (preplanned responses based on these results). They were then asked to monitor similar situations that were described as evoking negative responses and to exercise the Action Plans that were created. This process was repeated over a 3 month period with at most 3 Cognitive Catalysts and 3 associated Action Plans focused on.</p>
               <p><span style={{fontWeight: "bold"}}>Main findings:</span>  Using the methods suggested in the studies over a 3 month period, 89% of participants reported an increase in positive responses to previously negative “triggering” events and an overall decrease in negative responses in their daily lives. After 6 months, the majority of participants, 72%, reported not having to think about responding positively, they just responded positively and an additional 20% reported feeling their healthy responses were easier than at the 3 month mark.</p>
                </div></div></div>
                </section>  
				<Footer />
			</div>);
	}
}

export default Research;