import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import { Link, Redirect } from 'react-router-dom';
import { APIHost } from './GlobalVariables';
import BookCheckout from './BookCheckout';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
//const promise = loadStripe("pk_test_51HHMFBLb5KZXfDYgLFQAetYb7cGGuiegoT1Sjm9RE8FSyLA5QgYmlvJ2lkBlPVhQAkm3kVQHsbziLS18WztEybFy00SiEfx3Mm");
const promise = loadStripe("pk_live_51HHMFBLb5KZXfDYguK930v3O6ydugI72ThwFDVzIaKdqIVVOsmUDEHJCBm9E6eJAeLGSjvTNmdXNYLXfD9Q9G8R0005gh9V8zN");

class BookPurchase extends Component {

  constructor(props) {
    super(props);
    this.state = ({
      loading: true,
      logged_in: true,
      debit_credit: false,
      paypal: false
    });

  }
  componentDidMount() {
    window.scroll(0, 0);
    this.setState({ loading: false });

}

  render() {
    if (this.state.loading) {
      return (<h1>Loading...</h1>);
    } else {
      return (
        <div className="BookPurchase">
          <Header history={this.props.history} />
          <section className="two-sided-box">
            <div className="container">
              <div className="row justify-content-center">

                <div className="col-md-6">
                  <div className="d-flex justify-content-center">
                    <div>
                      <Elements stripe={promise}>
                        <BookCheckout />
                      </Elements>



                    </div>
                  </div>

                </div>

              </div>

            </div>
          </section>
          <Footer />
        </div>
      );
    }
  }
}

export default BookPurchase;