import React, { Component } from 'react';
import '../css/articles.css';
import Header from '../components/header';
import Footer from '../components/footer';

class Resentment extends Component {

componentDidMount() {
    window.scroll(0,0);
}

	render() {
		return (
		<div className="Resentment">
		<Header history={this.props.history} />

 
<section className="two-sided-box">

       <div className="container">
              <h1>The Insidious Nature of Resentment</h1><hr />
            <div className="two-sided-box-in">
    <p>
Unfulfilled expectations in a relationship can leave ugly traces behind. You expect someone to behave a certain way, treat you a certain way, do a particular thing, and they don't. Your reaction to this dictates the direction the relationship will take. Openly communicating your expectations and your perspective helps prevent these incidents and allows for a better mutual understanding. If not, then the most insidious of emotions takes root: Resentment. We harbor these feelings. We carry them with us and they become part of our perspective we have of whomever we are in this relationship with. As these infractions continue to accumulate, the resentment grows. The negative feelings are justified from our perspective. We continue to hold these feelings within us, never communicating them or their source. The day comes that a seemingly minor wrongdoing is committed and an explosion ensues. The person is left confused. Certainly something so minor doesn't warrant such a reaction, but the explosion is propelled by the force of resentment. It is finally time to let it all out. As we fire away, our partner is left frustrated and confused with nothing to do but fire back. They have plenty of the same ammunition that they have  hidden away all this time. The disastrous volleys continue until the relationship is left in ruins.     
</p>
    <p>
Resentment is the most insidious of emotions because it manifests itself in moments unrelated to the source of the emotion, leaving no clear path to a resolution. We carry these emotions as ammunition, waiting to be fired at our unwitting partner. Communicating expectations and our perspectives to our partner is crucial to avoiding these battles. Assuming the best of intentions and embracing the idea that all conflict has its source in misunderstandings that additional information can resolve can help in this avoidance. We are taught to avoid conflict, but conflict, when handled with care, can be the greatest of opportunities to avoid this disastrous emotion of resentment. Take the moment of conflict in isolation. Carrying previous conflict forward is like a giant cartoon snowball rolling out of control down a hill, gaining size and speed along the way. What could otherwise be a harmless and easily concluded conflict can be one of disastrous outcomes if we can't avoid these mistakes.   
</p>

    </div></div>
    </section>

<Footer history={this.props.history} />

</div>
		);
		
	}
}

export default Resentment;