import React, { Component } from 'react';
import './css/articles.css';
import Header from './components/header';
import Footer from './components/footer';
import { Link } from 'react-router-dom';
import { CheckCircleTwoTone } from '@ant-design/icons';

class Blog extends Component {
	render() {
		return (
		<div className="Blog">
		<Header history={this.props.history} />
            <section className="jumbotron jumbo-background">

    </section>

       
        <div className="container">
            <div className="row">

                <div className="col-md-8">
                    <section className="blog-grid">
                        <div className="row">
        <div className="col-sm-6">
                                <div className="grid-box">
                                    <figure>
<iframe src="https://player.vimeo.com/video/476484684" width="300" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>
                                    <h5>
An empowering lesson for relationships...what language should you express for relationship success?                                    </h5>

                                </div> 
                            </div>
                        <div className="col-sm-6">
                                <div className="grid-box">
                                    <figure>
<iframe src="https://player.vimeo.com/video/473954932" width="300" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>
                                    <h5>
To live an Authentic Life is to show-up in your life by your design. To get there, start by getting the little things right!                                    
</h5>

                                </div> 
                            </div>
<div className="col-sm-6">
                                <div className="grid-box">
                                    <figure>
<iframe src="https://player.vimeo.com/video/473964116" width="300" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>
                                    <h5>
                                    Here is a key question you must ask yourself in each moment you face, no matter how small of a moment you think it is!
                                    </h5>

                                </div> 
                            </div>
                            <div className="col-sm-6">
                                <div className="grid-box">
                                    <figure>
<iframe src="https://player.vimeo.com/video/462850861" width="300" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>
                                    <h5>
                                    Why is it that we wait for a crisis to take action towards something greater for ourselves or avoid disaster?
                                    </h5>

                                </div> 
                            </div>

                            <div className="col-sm-6">
                                <div className="grid-box">
                                <div className="for_iframe">
<iframe src="https://player.vimeo.com/video/462850573" width="300" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        
</div>
                                   <h5>
                                    
                                    Be more passionate about what's to come than what was. Direct your moment!<br /><br />
                                    </h5>

                                </div> 
                            </div>

                            <div className="col-sm-6">
                                <div className="grid-box">
                                    <figure>
<iframe src="https://player.vimeo.com/video/462850399" width="300" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>
                                    <h5>
                                    Seek healthy conflict within relationships to release the necessary tension and avoid resentment.
                                    </h5>

                                </div>
                            </div>

                            <div className="col-sm-6">
                                 <div className="grid-box">
                                    <h2 id="futureself" name="futureself">
                                        <Link to="/SelfCompassion">The Power of Self-Compassion!</Link>
                                    </h2>
                                    <p>
                                Compassion and empathy are sometimes confused. But empathy is identifying someone else’s feelings...                                    </p>
                                    <Link to="/SelfCompassion" className="blog-read-more">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="grid-box">
                                    <h2 id="futureself" name="futureself">
                                        <Link to="/FutureSelf">Honor your Future Self!</Link>
                                    </h2>
                                    <p>
                                        We live on the razor-thin line between our past and future...that is our moment. Every decision we make, every response we give to the world impacts the path we are on...
                                    </p>
                                    <Link to="/FutureSelf" className="blog-read-more">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                </div>
                            </div>
                                                        <div className="col-sm-6">
                                <div className="grid-box">
                                    <h2 id="sorry" name="sorry">
                                        <Link to="/Sorry">The ineffective Sorry</Link>
                                    </h2>
                                    <p>
                                        She sat there, wondering if he was going to text or call to explain why he was so late - again. This was
                                        the third night...<br /><br />
                                    </p>
                                    <Link to="/Sorry" className="blog-read-more">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="grid-box">
                                    <h2>
                                        <Link to="/LivingProactively">Living Proactively</Link>
                                    </h2>
                                    <p>
                                        We live very tactical and reactionary lives. We necessarily fail to see this. We make
										plans for vacations to ensure we have a happy family life...
                                    </p>
                                    <Link to="/LivingProactively" className="blog-read-more">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="grid-box">
                                    <h2>
                                        <Link to="/Resentment">The Insidious Nature of Resentment</Link>
                                    </h2>
                                    <p>
                                        Resentment is the most insidious of emotions because it manifests itself in moments unrelated to the
										source...
                                    </p>
                                    <Link to="/Resentment" className="blog-read-more">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                </div> 
                            </div>

						    <div className="col-sm-6">
                                <div className="grid-box">
                                    <h2>
                                        <Link to="/UnderReact">Underreact</Link>
                                    </h2>
                                    <p>
                                        He sits there, waiting more and more impatiently for your date to arrive. She is known to be late at
										times, but this is getting ridiculous. Finally, in she walks,
                                    </p>
                                    <Link to="/UnderReact" className="blog-read-more">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                </div> 
                            </div>



                        </div>

                    </section> 

                </div>

                <aside className="col-md-4">
                    <div className="sidebar-blog">
                        <div className="recent-post">

                            <h3>Recent Videos</h3><hr />
                            <div className="related-post">
                                <div className="media">
                                    <div className="media-left">
                                    <h5><Link to="LittleThingsRight">Get the Little Things Right!</Link></h5>
<iframe src="https://player.vimeo.com/video/473954932" width="150" height="100" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        

                                       
                                    </div>

                                </div>
                            </div> 

                        </div>
                        <div className="recent-post">

                            <h3>Recent Articles</h3><hr />

                            <div className="related-post">
                                <div className="media">
                                    <div className="media-body">
                                        <p className="post-text">
                                            <Link to="/FutureSelf">Honor your future self</Link><br />
                                             We live on the razor-thin line between our past and future...that is our moment. Every decision we make, every response we give to the world impacts the path we are on...
                                        </p>
                                    </div>
                                </div>
                            </div>



                        </div>



                    </div>
                </aside>

            </div>
        </div> 

<Footer history={this.props.history} />

</div>
		);
		
	//}	
		
	}
}

export default Blog;