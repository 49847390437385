import React, { useState, useEffect } from "react";
import Header from './components/header';
import Footer from './components/footer';
import './css/stripe.css';
import axios from 'axios';
import {APIHost} from './GlobalVariables';
import { Link, Redirect } from 'react-router-dom';

import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

const ServerFailure = () => (
<h5><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h5>
);

const CreditUpdateFailure = () => (
<h5><font color="red">We apologize. An error occurred while making the purchase. Support has been automatically contacted and will rectify as soon as possible.<br />
If you have any questions or concerns, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h5>
);

export default function BookCheckout(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [email, setEmail] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [discountCode, setDiscountCode] = useState(null);
  const [paymentID, setPaymentID] = useState(null);
  const [server_failure, setServerFailure] = useState(false);
  const [name, setName] = useState('');
  const [credit_update_failure, setCreditUpdateFailure] = useState(false);
  const [total, setTotal] = useState(15.00);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [attendanceType, setAttendanceType] = useState('person');
  const [eventID, setEventID] = useState(1);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
        axios.post(APIHost + '/api/createPaymentIntent', {
          withCredentials: true, amount: 1500
        }).then((res) => {
            setClientSecret(res.data.clientSecret);
            setPaymentID(res.data.paymentID);
            setTotal(parseFloat(15.00));
        }).catch((error) => {
          console.log("Error is " + error);
          setServerFailure(true);
        });
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);
    var charge = 0;

    const payload = await stripe.confirmCardPayment(clientSecret, {
      receipt_email: email,
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: name
        }
      }
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {

         setError(null);
          setProcessing(false);
          setSucceeded(true);
 
    }
  

  };

const ChangePrice = event => {
  if(event.target.name == "person") {
    setTotal(199.00);
    setAttendanceType("person");
    setEventID(1);
  } else {
    setTotal(49.99);
    setAttendanceType("zoom");
    setEventID(2);
  }
};
   /*<p>Have a discount code? Enter it here. <input value={discountCode} onChange={UpdateDiscountCode}/></p>
   <div className="radio-container">
<div className="flex-item">
<div className="radio-container2">
  <input type="radio" checked="checked" name="radio" />Attend in-person
  </div>
  </div>
  <div className="flex-item">
  <input type="radio" checked="checked" name="radio" />Attend on Zoom
  </div>
</div>
*/
  return (
          <div className="CheckoutForm">
    <form id="payment-form" onSubmit={handleSubmit}>
    <h4>Signed copy of The Myth of Living Better!</h4>



        <h6>Book: ${total}</h6>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email address"
      />
      <input
        type="text"
        value={name}
        onChange={(n) => setName(n.target.value)}
        placeholder="Name on card"
      />
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      <button
        disabled={processing || succeeded}
        id="submit"
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Purchase"
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded. Thank you!</p>
      {server_failure && <ServerFailure />}
      {credit_update_failure && <CreditUpdateFailure />}
    </form>

      </div>
  );
}
