import React, { Component } from 'react';
import '../css/articles.css';
import Header from '../components/header';
import Footer from '../components/footer';

class Sorry extends Component {

componentDidMount() {
    window.scroll(0,0);
}

	render() {
		return (
		<div className="Sorry">
		<Header history={this.props.history} />

 
<section className="two-sided-box">

       <div className="container">
              <h1>Don't say sorry...take action!</h1><hr />
            <div className="two-sided-box-in">
    <p>
She sat there, wondering if he was going to text or call to explain why he was so late - again. This was the third night in the last couple of weeks that he stayed out late and didn’t have the decency to let her know where he was or what was going on. She felt like someone turned the lights off as she was making her way through this maze of a relationship. Maybe this was the last bump into a wall she could take. The dead end she was all too familiar with. As acceptance settled in, the door opened. “Hey! Listen, I’m so sorry I’m late. We ended up having drinks after dinner and I lost track of time, then my phone died. Anyway, sorry…”. Suddenly, the light was turned on and the exit was right in front of her. “Sorry”, she thought. As if she hadn’t heard that before.    </p>
    <p>
Such an overused word so often declared without the action behind it to make the difference to the offended – sorry. As if to say, “I’m letting you know that I know I did something wrong and I feel badly about it so I think uttering that word will make you feel better and absolve me of my wrong doing”. Sure, apologies are nice as a starting point to express regret, but there is a difference between “Hey, I’m sorry”, and truly making amends. That difference is, action.     </p>
    <p>
“If you meant the apology you gave last week, then you wouldn’t have done this again tonight”. Exactly! What action did he take after the previous offenses besides the next-day flowers? She didn’t ask for flowers. She asked to be communicated with and for him to respect her time and their time. 
    </p>
    <p>
The path towards making amends is to accept responsibility for the actions that are motivating our now infamous word. It is to listen to those we have hurt and ask specifically what we can do to resolve the hurt feelings we may have caused. “You’re right. I should have texted as soon as dinner went over and before we had drinks. Next time, I’ll let you know, or better yet, ask you if it is OK if I stay longer than I planned. I’ll ask you ahead of time if you have any expectations for when I’ll be home or if we have plans”. So save the $34.99 on the flowers and listen – and then take action to ensure that those wishes weren’t just said out loud, but actually made a difference.     </p>

    </div></div>
    </section>

<Footer history={this.props.history} />

</div>
		);
		
	}
}

export default Sorry;