import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import './css/profile.css';
import { Card } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import FontAwesome from 'react-fontawesome';
import faStyles from './css/font-awesome/css/font-awesome.css';
import { Link } from 'react-router-dom';
import elliot from './images/elliot.jpg';
import scale from './images/At_Scale2.jpg';
import cover from './images/book_cover3.jpg';
import './css/articles.css';


class About extends Component {

	constructor(props) {
		super(props);

	}

	componentDidMount() {
		window.scroll(0, 0);
	}

	render() {
        /*Elliot grew-up on the North Shore outside of Boston, MA. He began public speaking in his teens as part of a state-wide student council, performing 
                                        student rights lectures across the state and advocating for student-led legislation. Once graduated, his passion for science and discovery brought him 
                                        to pursue a double-degree in physics and computer science. While working on his graduate degrees, his love of cognitive science took him away from his formal disciplines 
                                        towards independent research. He spent 15 years in self-funded research projects, with the resulting research forming a new form of psychotherapy, Cognitive Response Therapy. He then created a 
                                        formalized program, <a href="https://mycoreinsights.com/mft">Mental Fitness Training</a>, powered by these discoveries. Armed with his technology background, he set out to revolutionize access to mental wellness programs 
                                        by creating the first self-paced, self-directed, but guided mental wellness program leveraging the Agile/Scrum framework at <a href="https://mycoreinsights.com">My Core Insights</a>.
                                        */
			return (<div className="About">
				<Header history={this.props.history} />

               <section id="testimonial" className="features-area">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-5 col-lg-6">
                                <div className="testimonial-left-content mt-45">
                                    <h4 className="title mb-2"><u>A little</u> more about Elliot</h4>

                                    <p className="text">
                                        Elliot Barnett is an inspirational speaker, a cognitive science researcher, an entrepreneur, an author, and a technology innovator. Elliot’s unique combination of passions has enabled him to be positively 
                                        disruptive in both the technology and mental health industries. Elliot spent over 20 years studying, researching, and developing in the cognitive sciences, with 15 years of formalized research studies. 
                                        This work culminated in the identification of three scientific theories that empowered a formalized therapeutic approach, <a href="https://cognitiveresponsetherapy.info">Cognitive Response Therapy</a>, that has been adopted by licensed practitioners. 
                                        He used the intellectual property to create a formalized mental health program, <a href="https://mycoreinsights.com/mft">Mental Fitness Training</a> and was the first to combine the Agile/Scrum technology framework with a mental health program  
                                        at <a href="https://mycoreinsights.com">My Core Insights</a> to deliver the training.

                                        With 25 years of technology experience and over 20 years of technology leadership experience, Elliot has transformed engineering organizations to optimal performance across a variety of industries. 
                                        Elliot has run successful businesses in e-commerce, business and sales consulting, engineering consulting, and the point-of-sale industry.
                                        </p>

                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                  <div className="author-info d-flex align-items-center justify-content-center pt-5">
                    <iframe title="video" src="https://player.vimeo.com/video/884585227" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  </div>
                            </div>
                        </div>
                    </div>
                </section>
                         
				<Footer />
			</div>);
	}
}

export default About;