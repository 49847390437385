import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import './css/profile.css';
import { Card } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import FontAwesome from 'react-fontawesome';
import faStyles from './css/font-awesome/css/font-awesome.css';
import { Link } from 'react-router-dom';
import elliot from './images/elliot.jpg';
import scale from './images/At_Scale2.jpg';
import cover from './images/book_cover3.jpg';
import './css/articles.css';


class Workshops extends Component {

	constructor(props) {
		super(props);

	}

	componentDidMount() {
		window.scroll(0, 0);
	}

	render() {
			return (<div className="Workshops">
				<Header history={this.props.history} />
            <section className="perfect-moment">
        <div className="container">
            <div className="perfect-quote">
                <h4>
                    Do you want Elliot to deliver a keynote or run a workshop to empower your organization? Below are some of the currently offered workshops. Please <Link to={{ pathname: "/contact" }}>reach out</Link> for a more extensive list and to discuss details.
                </h4>
            </div> 
        </div>
    </section>
<section id="testimonial" className="features-area">
            <div className="container">
            <section id="testimonial" className="features-area">
            <div className="container">
            </div>
            </section>
                      <section id="testimonial" className="features-area">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-5 col-lg-6">
                  <div className="testimonial-left-content mt-45">
                    <div><h4 className="title mb-2"><u>Let Elliot</u> empower your organization's mental wellness!</h4></div>
                    <img src={scale} alt="At Scale" className="img-fluid center-image" />
                    <h5>Imagine addressing the mental wellness challenges in your organization for a fraction of the cost of replacing a single employee! Learn More at <a href="https://mycoreinsights.com/ForBusiness">My Core Insights</a>.</h5>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="author-info d-flex align-items-center justify-content-center pt-5">
<iframe src="https://player.vimeo.com/video/506243757" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                  </div>

                </div>
              </div>
            </div>
          </section>
  
                                   <div className="row justify-content-between">

                     <div className="col-xl-5 col-lg-6">
                    <h4 className="title mb-2">How Scrum and the Science of Language can Transform your Life!</h4>
                        <p className="text">To maximize organizational effectiveness, it’s crucial to optimize the mental wellness of each individual in the organization. How? By combining powerful mental wellness programs with the Scrum framework to inspire and motivate these individuals to live the life they deserve - their Optimal Life! 
                        Come learn how the science of language can help answer the mental wellness challenge. Learn how it can transform negative cycles into powerful, positive narratives, enabling you to define, restructure, and strengthen what’s within.! 
                         </p>

                                    </div>
                                    <div className="col-lg-6">
                    <h4 className="title mb-2">Leverage your subconscious for success!</h4>
                        <p className="text">In this powerful workshop you will learn what drives you in your life and how to leverage it towards living your Optimal Life. You will learn how to define success for yourself and how your personal mission statement and personal brand impacts your ability to succeed. You will learn the impact of language on how you show up in your life and how to leverage language to empower you in your success. You will learn all the basics of the subconscious and how to train it to serve you instead of it driving you. As a bonus, Elliot will be discussing the basics of conflict and stress management! This is a hands-on-keyboard experience where you learn by listening AND doing. 
                         </p>

                                    </div>
                                    </div></div></section>  
              <section id="testimonial" className="features-area">
            <div className="container">
 
                                   <div className="row justify-content-between">

                     <div className="col-xl-5 col-lg-6">
                    <h4 className="title mb-2">Optimize Actions Optimize Belief Optimize your Life!</h4>
                        <p className="text">In this powerful workshop you will learn strategies to maximize your effectiveness each and every moment you live. You will learn how your subconscious can serve your success instead of it holding you back from it. You will learn the science behind procrastination and how to break free of the paralyzing feelings procrastination gives to you. You will discover strategies that will continue to empower you and align you to live an Optimal Life. You get one shot at this life. Learn how to make it an Optimal Life! As a bonus, Elliot will be discussing success principles and how the technology approach of Agile and SCRUM can empower your success! 
                         </p>

                                    </div>
                                    <div className="col-lg-6">
                    <h4 className="title mb-2">Live Better! Resolve your Inner Conflicts!</h4>
                        <p className="text">You deserve to live the life you want, your Optimal Life! But there are inner conflicts that can hold you back and negatively impact your mental and physical health. In this powerful workshop you will learn about the concept of Cognitive Traps and how to resolve them. Research studies have shown that these inner disconnects cause tension, stress, and damage your mental and physical well-being. With powerful intellectual property offered in this workshop, you will take action towards resolving them here and now and learn the tools to continue to strengthen what's within so you can achieve Optimal Mental Fitness and living your Optimal Life!  
                         </p>

                                    </div>
                                    </div></div></section>  
                         
				<Footer />
			</div>);
	}
}

export default Workshops;