import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import logo from '../images/logo_white.png';
import '../css/header.css';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			display_items: false
		}
		this.ToggleItems = this.ToggleItems.bind(this);
		this.DisplayHeader = this.DisplayHeader.bind(this);

	}

	componentDidMount() {

	}

	ToggleItems(event) {
		event.preventDefault();
		this.setState({display_items: !this.state.display_items});
	}

	DisplayHeader() {
		var display = [];
		if (this.state.display_items) {
			display.push(
				<>
					<button className="navbar-toggler" type="button" onClick={this.ToggleItems}>
						<span className="navbar-toggler-icon"></span>
						<span className="navbar-toggler-icon"></span>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/" }}>Home</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/About" }}>About</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/Workshops" }}>Workshops</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/Blog" }}>Blog</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/Research" }}>Research</Link>
							</li>

							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/Contact" }}>Contact</Link>
							</li>

							<li className="nav-item">
								<Link to={{ pathname: "/Register" }} className="nav-link">Register</Link>
							</li>
						</ul>
					</div>
					</>
	
			);
	} else {
			display.push(
				<>
					<button className="navbar-toggler" type="button" onClick={this.ToggleItems}>
						<span className="navbar-toggler-icon"></span>
						<span className="navbar-toggler-icon"></span>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/" }}>Home</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/About" }}>About</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/Workshops" }}>Workshops</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/Blog" }}>Blog</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/Research" }}>Research</Link>
							</li>

							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/Contact" }}>Contact</Link>
							</li>

							<li className="nav-item">
								<Link to={{ pathname: "/Register" }} className="nav-link">Register</Link>
							</li>
						</ul>
					</div>
				</>
			);
	}

		return display;
	
	}

	render() {
		return (
			<div className="Header">
				<nav className="navbar navbar-expand-lg heading_nav">
					<div className="container">
						<a className="navbar-brand" href="/">
							<img src={logo} alt="Elliot Barnett" className="img-fluid" width="120" height="120" />
						</a>
						{this.DisplayHeader()}
					</div>
				</nav>
			</div>
		);
	}
}

export default Header;