import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { Link, withRouter, Redirect  } from 'react-router-dom';
import {APIHost} from './GlobalVariables';
import axios from 'axios';
import Date from 'dropdown-date';

const MessageFailed = () => (
	    <h5><font color="red">Message failed to send</font></h5>
	);

const MessageSent = () => (
        <h5><font color="blue">Message sent successfully</font></h5>
    );

class Admin extends Component {

	constructor(props) {
		super(props);
        this.state = {
        	laoding: true,
        	isLogged: true,
        	is_admin: true,
        	admins: [],
        	user_details: [],
        	all_users: [],
        	time_options: [{label: '12:00 AM', value: '12:00 AM'},
            {label: '12:15 AM', value: '12:15 AM'},
            {label: '12:30 AM', value: '12:30 AM'},
            {label: '12:45 AM', value: '12:45 AM'},
            {label: '1:00 AM', value: '1:00 AM'},
            {label: '1:15 AM', value: '1:15 AM'},
            {label: '1:30 AM', value: '1:30 AM'},
            {label: '1:45 AM', value: '1:45 AM'},
            {label: '2:00 AM', value: '2:00 AM'},
            {label: '2:15 AM', value: '2:15 AM'},
            {label: '2:30 AM', value: '2:30 AM'},
            {label: '2:45 AM', value: '2:45 AM'},
            {label: '3:00 AM', value: '3:00 AM'},
            {label: '3:15 AM', value: '3:15 AM'},
            {label: '3:30 AM', value: '3:30 AM'},
            {label: '3:45 AM', value: '3:45 AM'},
            {label: '4:00 AM', value: '4:00 AM'},
            {label: '4:15 AM', value: '4:15 AM'},
            {label: '4:30 AM', value: '4:30 AM'},
            {label: '4:45 AM', value: '4:45 AM'},
            {label: '5:00 AM', value: '5:00 AM'},
            {label: '5:15 AM', value: '5:15 AM'},
            {label: '5:30 AM', value: '5:30 AM'},
            {label: '5:45 AM', value: '5:45 AM'},            
            {label: '6:00 AM', value: '6:00 AM'},
            {label: '6:15 AM', value: '6:15 AM'},
            {label: '6:30 AM', value: '6:30 AM'},
            {label: '6:45 AM', value: '6:45 AM'},
            {label: '7:00 AM', value: '7:00 AM'},
            {label: '7:15 AM', value: '7:15 AM'},
            {label: '7:30 AM', value: '7:30 AM'},
            {label: '7:45 AM', value: '7:45 AM'},
            {label: '8:00 AM', value: '8:00 AM'},
            {label: '8:15 AM', value: '8:15 AM'},
            {label: '8:30 AM', value: '8:30 AM'},
            {label: '8:45 AM', value: '8:45 AM'},
            {label: '9:00 AM', value: '9:00 AM'},
            {label: '9:15 AM', value: '9:15 AM'},
            {label: '9:30 AM', value: '9:30 AM'},
            {label: '9:45 AM', value: '9:45 AM'},
            {label: '10:00 AM', value: '10:00 AM'},
            {label: '10:15 AM', value: '10:15 AM'},
            {label: '10:30 AM', value: '10:30 AM'},
            {label: '10:45 AM', value: '10:45 AM'},
            {label: '11:00 AM', value: '11:00 AM'},
            {label: '11:15 AM', value: '11:15 AM'},
            {label: '11:30 AM', value: '11:30 AM'},
            {label: '11:45 AM', value: '11:45 AM'},
            {label: '12:00 PM', value: '12:00 PM'},
            {label: '12:15 PM', value: '12:15 PM'},
            {label: '12:30 PM', value: '12:30 PM'},
            {label: '12:45 PM', value: '12:45 PM'},
            {label: '1:00 PM', value: '1:00 PM'},
            {label: '1:15 PM', value: '1:15 PM'},
            {label: '1:30 PM', value: '1:30 PM'},
            {label: '1:45 PM', value: '1:45 PM'},
            {label: '2:00 PM', value: '2:00 PM'},
            {label: '2:15 PM', value: '2:15 PM'},
            {label: '2:30 PM', value: '2:30 PM'},
            {label: '2:45 PM', value: '2:45 PM'}, 
            {label: '3:00 PM', value: '3:00 PM'},
            {label: '3:15 PM', value: '3:15 PM'},
            {label: '3:30 PM', value: '3:30 PM'},
            {label: '3:45 PM', value: '3:45 PM'}, 
            {label: '4:00 PM', value: '4:00 PM'},
            {label: '4:15 PM', value: '4:15 PM'},
            {label: '4:30 PM', value: '4:30 PM'},
            {label: '4:45 PM', value: '4:45 PM'}, 
            {label: '5:00 PM', value: '5:00 PM'},
            {label: '5:15 PM', value: '5:15 PM'},
            {label: '5:30 PM', value: '5:30 PM'},
            {label: '5:45 PM', value: '5:45 PM'}, 
            {label: '6:00 PM', value: '6:00 PM'},
            {label: '6:15 PM', value: '6:15 PM'},
            {label: '6:30 PM', value: '6:30 PM'},
            {label: '6:45 PM', value: '6:45 PM'}, 
            {label: '7:00 PM', value: '7:00 PM'},
            {label: '7:15 PM', value: '7:15 PM'},
            {label: '7:30 PM', value: '7:30 PM'},
            {label: '7:45 PM', value: '7:45 PM'}, 
            {label: '8:00 PM', value: '8:00 PM'},
            {label: '8:15 PM', value: '8:15 PM'},
            {label: '8:30 PM', value: '8:30 PM'},
            {label: '8:45 PM', value: '8:45 PM'}, 
            {label: '9:00 PM', value: '9:00 PM'},
            {label: '9:15 PM', value: '9:15 PM'},
            {label: '9:30 PM', value: '9:30 PM'},
            {label: '9:45 PM', value: '9:45 PM'}, 
            {label: '10:00 PM', value: '10:00 PM'},
            {label: '10:15 PM', value: '10:15 PM'},
            {label: '10:30 PM', value: '10:30 PM'},
            {label: '10:45 PM', value: '10:45 PM'}, 
            {label: '11:00 PM', value: '11:00 PM'},
            {label: '11:15 PM', value: '11:15 PM'},
            {label: '11:30 PM', value: '11:30 PM'},
            {label: '11:45 PM', value: '11:45 PM'}
            ],
            selected_time: '12:00 PM',
            room_title: '',
            message_sent: false,
            message_failed: false,
            its_me: false,
            rooms: []

        };

        this.GetAllUsers = this.GetAllUsers.bind(this);
        this.onChange = this.onChange.bind(this);
        this.SubmitMessage = this.SubmitMessage.bind(this);
        this.submitRoom = this.submitRoom.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.DropdownChange = this.DropdownChange.bind(this);
        this.DisplayLink = this.DisplayLink.bind(this);
        this.SendPush = this.SendPush.bind(this);
        this.NotifyPush = this.NotifyPush.bind(this);
        this.NotifySMS = this.NotifySMS.bind(this);
        this.NotifyEmail = this.NotifyEmail.bind(this);

    }

    componentDidMount() {
        console.log("Here");
		window.scroll(0, 0);
		var promises = [];
		var tmp_admins = [];
		var tmp_user_details = [];
		var tmp_all_users = [];

		        var all_promise = this.GetAllUsers().then(all_users => {
		            tmp_all_users = all_users.data.user_details;

                    console.log("Back from all users " + all_users.data.all_users.length);
                    this.setState({all_users: all_users.data.all_users}, () => {this.setState({loading: false})});
		        }).catch((error) => {
		            console.log("GetAllUsers failed " + error);
		            this.setState({server_failure: true});
		        });


	}

NotifyPush(event) {
        this.setState({notify_push: true})

          axios.post(APIHost + '/api/notifyAboutRoomPush', 
            {
                withCredentials: true, 
                title: this.state.room_title,
                date: this.state.event_date,
                description: this.state.roomdescription,
                time: this.state.selected_time,
                creator: this.state.user_details.name,
                new_room: true

            }).catch((error) => {  
                console.log("Notifying via push failed " + error);
            });      
}

NotifySMS(event) {
        this.setState({notify_sms: true})

          axios.post(APIHost + '/api/notifyAboutRoomSMS', 
            {
                withCredentials: true, 
                title: this.state.room_title,
                date: this.state.event_date,
                description: this.state.roomdescription,
                time: this.state.selected_time,
                creator: this.state.user_details.name,
                new_room: true

            }).catch((error) => {  
                console.log("Notifying via SMS faile " + error);
            });      
}

NotifyEmail(event) {
    this.setState({notify_email: true})
          axios.post(APIHost + '/api/notifyAboutRoomEmail', 
            {
                withCredentials: true, 
                title: this.state.room_title,
                date: this.state.event_date,
                description: this.state.roomdescription,
                time: this.state.selected_time,
                creator: this.state.user_details.name,
                new_room: true

            }).catch((error) => {  
                console.log("Notifying via email failed" + error);
            });      
}

SendPush() {
              axios.post(APIHost + '/api/sendSMS', 
            {
                withCredentials: true, 
                message: this.state.pushMessage

            }).then(() => {
                this.setState({send_push: true});
            }).catch((error) => {  
                console.log("Sending push failed " + error);
            });  
}

DisplayLink() {
    var display = [];
    display.push(<div><p>Successfully setup Event</p></div>);

    return display;
}

    DropdownChange(event) {
        this.setState({selected_time: event.target.value});
    }

    onDateChange(date) {
        this.setState({event_date: date});
    }

submitRoom(event) {
    event.preventDefault();
    if (this.state.room_title == null || this.state.room_title == '') {
        this.setState({title_error: true});
    }
    if (this.state.event_date == undefined) {
        this.setState({date_error: true});
    }

        axios.post(APIHost + '/api/createRoom', 
            {
                withCredentials: true, 
                title: this.state.room_title,
                link: this.state.attendeeLink,
                date: this.state.event_date,
                time: this.state.selected_time,
                description: this.state.roomdescription,
                creator: this.state.user_details.name

            }).then(() => {
    this.setState({title_error: false, date_error: false, display_link: true, created_room: true});
            }).catch((error) => {  
                console.log("inserting the meeting failed " + error);
            });

}


    async GetAllUsers() {
        const res = await axios.get(APIHost + '/api/getAllUsers', { withCredentials: true });
        return await res;
    }

   onChange(event) {
        this.setState({ [event.target.name]: event.target.value, submitted_message: false});
    }

    SubmitMessage(event, to_me) {
    axios.post(APIHost + '/api/generalMessage', 
    	{
    		withCredentials: true,
    		message: this.state.message,
            subject: this.state.subject,
            to_me: to_me
    	}).then(() => {
    		this.setState({message_sent: true});
    	}).catch((error) => {
    		console.log("Message failed");
    		this.setState({message_failed: true});
    	});
    }

	render() {

		   var display_create_room = [];

        
        	var display_users = [];
        	this.state.all_users.forEach((user) => {
        		/*var date = new Date(user.last_activity);
        		var last_activity = (date.getMonth()+1) + "-" + date.getDay() + "-" + date.getUTCFullYear();*/

        		display_users.push(<tr><td>{user.id}</td><td className="td-spacing1">{user.name}</td><td className="td-spacing2">{user.email}</td><td className="td-spacing3">{user.phonenumber}</td><td className="td-spacing4">{user.last_activity}</td></tr>)
        		//display_users.push(<div className="flexbox-container"><div>{user.name}</div><div>{user.email}</div><div>{user.phonenumber}</div><div>{last_activity}</div></div>);
        	});
                   if (this.state.loading) {
            return (<div className="Home"><Header /><h3 className="p-3">Loading...</h3></div>);

        } else {
		return(
			<div className="Admin page-container">
			<div className="inner-container">
			<Header history={this.props.history} loggedIn={this.state.isLogged} />

			<div className="text-center">
			            {this.state.display_link && this.DisplayLink()}
			            </div>
			<div className="flexbox-container">
			<div className="form-input">
											<div className="input-items default">
																	<h5>This is used to broadcast an email message to all users - it accepts HTML only!</h5>

												<textarea onChange={this.onChange}  rows="5" cols="10" id="message" name="message"></textarea>
											</div>
                                                                                <div className="form-input mt-25">
                                            <div className="input-items default">
                                                <input type="text" id="subject" name="subject" onChange={this.onChange} placeholder="Subject" />
                                            </div>
                                        </div>
											<div className="flexbox-container">
											<div className="flex-item">
											<div className="flexbox-container">
											<div><button className="simple-button" disabled={this.state.message_sent} onClick={this.SubmitMessage}>Send</button></div>
                                            <div><button className="simple-button" disabled={this.state.message_sent} onClick={(e) => this.SubmitMessage(e, true)}>Send To Me</button></div>
											</div>
                                            {this.state.message_failed && <MessageFailed />}
                                            {this.state.message_sent && <MessageSent />}
											</div></div>
										</div>
										</div>
<div className="flexbox-container">
                                                    <div className="form-input">
                                            <div className="input-items default">
                                                                    <h5>This is used to send a push notification to everyone!</h5>

                                                <textarea onChange={this.onChange}  rows="5" cols="10" id="pushMessage" name="pushMessage"></textarea>
                                            </div>
                                            <div className="flexbox-container">
                                            <div className="flex-item">
                                            <div className="flexbox-container">
                                            <div><button className="simple-button" disabled={this.state.push_sent} onClick={this.SendPush}>Send</button></div>
                                            </div>
                                            {this.state.message_failed && <MessageFailed />}
                                            {this.state.message_sent && <MessageSent />}
                                            </div></div>
                                        </div>
                                        </div>
                                        
			<div className="center-table"><table>{display_users}</table></div>
			</div>
			<Footer history={this.props.history} loggedIn={this.state.isLogged} />
			</div>
			);
		}
    }
	
}

export default withRouter(Admin);