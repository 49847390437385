import React, { Component } from 'react';
import '../css/articles.css';
import Header from '../components/header';
import Footer from '../components/footer';

class FutureSelf extends Component {

componentDidMount() {
    window.scroll(0,0);
}

	render() {
		return (
		<div className="FutureSelf">
		<Header history={this.props.history} />

 
<section className="two-sided-box">

       <div className="container">
              <h1>Honoring your future self</h1><hr />
            <div className="two-sided-box-in">
    <p>
I’ve talked a lot about learning from your past, influencing your future, but directing your moment. We live on the razor-thin line between our past and future...that is our moment. Every decision we make, every response we give to the world impacts the path we are on. The future is the unrealized potential of infinite possibilities. As soon as you make a decision and respond to the world, those possibilities collapse into a single reality and become the past, driving you along your now chosen path. Sounds scary doesn’t it? </p>
<p>
Well, it doesn’t have to be! In Mental Fitness Training, we learn to design our Mental Core so we start to show up in our life by our design and start responding to life the way we want to. We start living a proactive instead of a reactive life. Why is this so important? It impacts your future self. See, you live your moments now, but the decisions you make don’t impact your current self. They impact your future self, the one that has to live with how you responded to your life. You are not only beholden to the person you are now, but to the person you WILL be in the future. The struggles you allow. The conflict you pursue. The resentment that you grow. The anger that you allow to drive you. All of this impacts the you of your next moment, your next day, your next year...your entire future.</p>
<p> Are you starting to see why it is so crucial to start working on yourself in your current moment? For you to start designing your Mental Core so you start showing up in your life and driving your moments towards a future self that reaps the rewards of your work? Learn from your past, invest in yourself and live your current moment with a healthy and strong Mental Core so the influenced future becomes your Optimal Life. </p>
<br /><p>Elliot Barnett</p>
<p>Founder, My Core Insights and Mental Fitness Training</p>

    </div></div>
    </section>

<Footer history={this.props.history} />

</div>
		);
		
	}
}

export default FutureSelf;