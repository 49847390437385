import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import './css/profile.css';
import { Card } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import FontAwesome from 'react-fontawesome';
import faStyles from './css/font-awesome/css/font-awesome.css';
import { Link, withRouter } from 'react-router-dom';
import elliot from './images/speaking_nobackground2.png';
import scale from './images/At_Scale2.jpg';
import cover from './images/book_cover3.jpg';
import seminar from './images/seminar.jpg';
import oneOnOne from './images/one-on-one.jpg';
import MCI from './images/My-Core-Insights-Cropped.png';
import OMLogo from './images/OurMindsetLogoCropped.jpg';
import CRT from './images/crt-high-resolution-logo.png';
import axios from 'axios';
import {APIHost} from './GlobalVariables';

import './css/articles.css';


class Home extends Component {

	constructor(props) {
		super(props);
        this.state = {

        }
        //this.DirectToBiz = this.DirectToBiz.bind(this)
        this.DirectToRegister = this.DirectToRegister.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
        axios.post(APIHost + '/api/updateCount', { withCredentials: true, page: 'home' }).catch((error) => {
            console.log("Failed to update count " + error);
        });
	}

   DirectToRegister() {
              //navigate('/Register');
        this.props.history.push({ pathname: "/Register", state: { loggedIn: true } });

    }

	render() {
			return (<div className="Home">
				<Header history={this.props.history} />

               <section id="testimonial" className="testimonial-area">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-5 col-lg-6">
                                <div className="testimonial-left-content mt-45">
                                    <h6 className="sub-title">Welcome To!</h6>
                                    <h4 className="title mb-2"><u>The Home</u> of Elliot Barnett</h4>
                                    <h6>Elliot's passion is to empower each of you to live your Optimal Life! Explore this page to find out how!</h6>
                                     {/*<ul className="testimonial-line">
                                            <li>Foo</li>
                                            <li>Foobar</li>
                                            <li>Another line</li>
                                            <li>Here's more info to see hwat happens</li>
                                        </ul> */}
                                    <p className="text">
                                        Elliot Barnett is an inspirational public speaker, a cognitive science researcher, an author, a technology innovator, and a powerful disruptor in the mental health industry.
                                        His passion is to empower everyone to live their Optimal Life. Elliot has spent over 20 years researching solutions to the human equation culminating
                                        in foundational scientific theories that empowered a new framework for psychotherapy, <a href="https://cognitiveresponsetherapy.info">Cognitive Response Therapy</a>. Elliot has pioneered a new social, e-learning mobile platform focused on improving people's quality of life. You can download the Our Mindset mobile app for <a className="link" href="https://apps.apple.com/us/app/our-mindset/id1661721366">IOS</a> or <a className="link" href="https://play.google.com/store/apps/details?id=com.OurMindset.Social">Android</a>.
                                        </p><br />
                                    <h5>"You get one shot at this life. Let's work together to make it an Optimal Life!" -Elliot Barnett</h5>

                                </div>
                            </div>
                            <div className="col-lg-6 py-5">
										<img src={elliot} alt="Elliot Barnett"  className="photo" />

                            </div>
                        </div>
                    </div>
                </section>
                                          <section id="services" className="features-area">
            <div className="container">
              <div className="row justify-content-between">
            <div className="form-input light-rounded-buttons mt-3 text-center">
                                       <h3>Join the community! You'll get notified of Elliot's appearances, special offers, exclusive content, and more! <button onClick={this.DirectToRegister} className="main-btn light-rounded-two">Register</button>
                                   </h3> </div>
                                    </div>
                                    </div></section>
                          <section id="testimonial" className="testimonial-area">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-5 col-lg-6">
                  <div className="testimonial-left-content mt-45">
                    <div className="text-center"><h4 className="title mb-2"><u>The Myth</u> of Living Better now on Amazon</h4></div>
                    <h5>Here's what they don't tell you about living an Optimal Life!</h5><br/>
                    <p>Get the 5-star rated book from Elliot now on <a href="https://www.amazon.com/dp/B09MYTNTBM">Amazon</a>. The Myth of Living Better is a thoughtful tale of self-discovery and self-mastery. 
                    It is a journey to discover what true success means, what living a life of Purposeful Meaning is, and ultimately what living an Optimal Life truly means. You will feel the struggles and triumphs the main character Jeremy 
                    faces as if they are your own, and as you follow Jeremy’s journey, you will discover what They don’t tell you about how to live an Optimal Life.</p>
                  </div>
                </div>
                <div className="col-lg-6">
            <img src={cover} alt="The Myth of Living Better" className="img-fluid image-padding" />
              </div>
            </div>
            </div>
          </section>
          <section className="features-area">
        <div className="feature-section">
<h2 className="section-title"><u>Empower your</u> practice with Cognitive Response Therapy!</h2>
  <div className="feature-list">
    <div className="feature-column">
      <p><i className="checkmark">✓</i> Easily integrated into existing practices</p>
      <p><i className="checkmark">✓</i> Addresses foundational challenges</p>
    </div>
    <div className="feature-column">
      <p><i className="checkmark">✓</i> Existing programs are available</p>
      <p><i className="checkmark">✓</i> Evidence based and research driven</p>
    </div>
  </div>
  <p className="feature-description">
                  Join many other mental health practitioners and coaches that have learned how to incorporate Cognitive Response Therapy into their existing practice. <a href="https://cognitiveresponsetherapy.info/Training">Training</a> workshops are offered monthly with self-directed training coming soon!

  </p>
</div>
</section>



                                          <section id="services" className="testimonial-area">
                    <div className="container">
                    <div  className="text-center"><br /><br /><h2><u>Here's what</u> people say about Elliot</h2></div>
                        <div className="row justify-content-center">

                            <div className="col-lg-4 col-md-7 col-sm-9">
                                <div className="single-features mt-40">
                                    <div className="features-content">
                                        <h6 className="para">
                                            "Elliot is such an inspirational speaker and his knowledge of cognitive science and the subconscious really came across during the workshops.
Elliot kept us engaged through his thought-provoking content and the interactive design of his platform that allowed everyone to complete the inner work moving us towards what he describes as Optimal Mental Fitness. 
At the end of the workshops, each participant had completed a self-assessment and developed tangible action steps to achieve their Optimal Life – personally & professionally."
                                            </h6>
                                        <p className="text"><br />- Ulrike Berzau<br />MM MHS PT FACHE</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-7 col-sm-9">
                                <div className="single-features mt-40">
                                    <div className="features-content">
                                        <h6 className="para">
                                            "Elliot has taken his innovative approach to business and applied to mental wellness to make it affordable, accessible and most importantly de-stigmatized. The agile platform he created to guide people on their mental wellness journey meets them where they are as human beings, without judgement or labels, and fits neatly into a corporate wellness program. His extraordinary understanding of the subconscious' role in behavior and how to strengthen the "mental core" underpins the effectiveness of this universally appealing modality.
                                            "
                                            </h6>
                                        <p className="text"><br />- Maura Barclay <br />CCMP-NFP</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-7 col-sm-9">
                                <div className="single-features mt-40">
                                    <div className="features-content">
                                        <h6 className="para">
                                            "My Core Insights IS the missing puzzle piece. The part that doesn't get learned in therapy. How we respond to the thoughts that we have instead of trying to control what we think about. How we speak to ourselves in any one given situation. My Core Insights and the incredible program Elliot offers is a process that puts into perspective a model that not only is not taught, but it also simply doesn't exist anywhere else. "
                                            </h6>
                                        <p className="text"><br />- Katherine Layman<br /><br /><br /><br /><br /><br /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                          <section id="testimonial" className="features-area">

    <div className="container">
    <div className="text-center"><h2><u>Ways to</u> work with Elliot</h2></div>

       
                    <section className="blog-grid">
                        <div className="row justify-content-center">
        <div className="col-lg-4 col-md-7 col-sm-9">
                                <div className="grid-box">
                                <h2>Workshops</h2>
                                    <figure>

                                    <img src={seminar} alt="Workshops" className="img-fluid" />
                                    <figcaption className="bookmark">

                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>
                                    <h5>
                                    Elliot runs powerful and actionable workshops for companies and organizations. Check the <Link to="/Workshops">Workshops</Link> page for details.
                                   </h5>

                                </div> 
                            </div>
                        <div className="col-lg-4 col-md-7 col-sm-9">
                                <div className="grid-box">
                                <h2>Our Mindset</h2>
                                    <figure>
                                    <img src={OMLogo} alt="Our Mindset" className="img-fluid" />
<figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>
                                    <h5>
Our Mindset is a social, e-learning mobile platform where you will find courses and live events provided by Elliot. <a href="https://ourmindset.io">Come join us!</a></h5>
                                </div> 
                            </div>
<div className="col-lg-4 col-md-7 col-sm-9">
                                <div className="grid-box">
                                <h2>Cognitive Response Therapy</h2>
                                    <figure>
                                    <img src={CRT} alt="CRT" className="img-fluid" />
                                    <figcaption className="bookmark">

                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>
                                    <h5>
The official site for <a href="https://cognitiveresponsetherapy.info">Cognitive Response Therapy</a>. Find training, information, and more</h5>

                                </div> 
                            </div>

                        </div>

                    </section> 

              
                </div>
</section>



				<Footer />
			</div>);
	}
}

export default withRouter(Home);