import React, { Component } from 'react';
import '../css/articles.css';
import Header from '../components/header';
import Footer from '../components/footer';

class LittleThingsRight extends Component {

componentDidMount() {
    window.scroll(0,0);
}

	render() {
		return (
		<div className="LittleThingsRight">
		<Header history={this.props.history} />

 
<section className="two-sided-box">

       <div className="container">
              <h1>Get the Little Things Right!!</h1><hr />
            <div className="two-sided-box-in">
            <p>
<iframe src="https://player.vimeo.com/video/473954932" width="700" height="400" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> 
</p>
    </div></div>
    </section>

<Footer history={this.props.history} />

</div>
		);
		
	}
}

export default LittleThingsRight;